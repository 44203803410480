import React, { useEffect, useState } from "react";
import Navbar from "../../Common/Navbar";
import ProjectBanner from "../../Common/ProjectBanner";
import "../../../assets/css/Project.css";
import "react-modal-video/css/modal-video.css";
import ModalVideo from "react-modal-video";
import Footer from "../../Common/Footer";
import { Modal, notification } from "antd";
import { axiosInstance, Config } from "../../../config";
import { useParams } from "react-router-dom";
import { revertSlug } from "../../../utils/SlugGenerator";
import Loader from "../../Common/Loader";
import CTA from "../../Common/CTA";
import axios from "axios";
import MakeAppointment from "../../Common/MakeAppointment";
import ContactModal from "../../Common/ContactModal";
import Slider from "react-slick";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

const ProjectDetail = () => {
  const [show, setShow] = useState(false);
  const [contact, setContact] = useState(false);
  const [type, setType] = useState('')
  const [loading, setLoading] = useState(false);
  const [project_details, setProjectDetails] = useState([]);
  const [gallery, setGallery] = useState([]);
  const [cta, setCta] = useState({});
  const [floor_label, setFloorPlanLabel] = useState([]);
  const [floor_project, setFloorProject] = useState([]);
  const [contactSecton, setContactSection] = useState({});
  const [inquiry_button, setEnquiryButton] = useState("");
  const [backImage, setBackImage] = useState({});
  const [floorImages, setFloorImage] = useState([])

  const params = useParams();

  useEffect(() => {
    getProjects();
  }, []);
  function getProjects() {
    setLoading(true);
    axiosInstance
      .get(`projects/${params?.name}`)
      .then((res) => {
        setProjectDetails(res?.data?.project_page?.project_info);
        setGallery(res?.data?.project_page?.gallery);
        setCta(res?.data?.project_page?.cta_section);
        setFloorPlanLabel(res?.data?.project_page?.label);
        setContactSection(res?.data?.project_page?.contact_section);
        setEnquiryButton(
          res?.data?.project_page?.enquiry_button?.enquiry_title
        );
        setBackImage(res?.data?.project_page?.enquiry_button);
        setFloorImage(res?.data?.project_page?.project_info?.floor_images)
        setFloorProject(JSON.parse(res?.data?.project_page?.prices?.price));
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  }

  const parsePrices = (priceString) => {
    // Split the string into individual items
    const items = priceString;

    const structureTemplate = items[0]?.split(",");

    return items.map((item) => {
      const values = item.split(",");
      const dynamicObject = {};

      structureTemplate.forEach((_, index) => {
        if (floor_label[index]) {
          const key = floor_label[index].label;
          dynamicObject[key] = values[index];
        }
      });

      return dynamicObject;
    });
  };

  const parsedPrices = parsePrices(floor_project);

  // Registeration Form
  var settings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 5000,
  };

  const onClose = () => {
    setContact(false)
  }


  const onHandleClick = async (type ) => {
    setContact(true);
    try {
      const res = await axiosInstance.get(`count/${type}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      });
    } catch (error) {
      console.log(error);
    }
  }


  return (
    <div className="mobile-overflow-x">
      {loading && <Loader />}
      <Navbar />
      <ProjectBanner
        projectname={project_details?.project_name}
        area={project_details?.project_loc}
        price={project_details?.price || "-"}
        size={project_details?.size || "-"}
        config={project_details?.configuration || "-"}
        status={project_details?.category?.name || "-"}
        rera={project_details?.rera_no || "-"}
        backImage={project_details?.project_bg}
        ProjectStatus={project_details?.project_status_data}
      />

      <div className="container">
        <div className="section-padding">
          <div className="row justify-space-between">
            <div className="col-md-5">
              <div className="featureprojects pb-0">
                <span className="subTitle">{project_details?.info_title}</span>
                <h2 className="text-black  ">
                  {project_details?.project_name}
                </h2>
              </div>
              <div
                dangerouslySetInnerHTML={{
                  __html: project_details?.project_info,
                }}
              />
              <div className=" mt-3">
                <button
                  className="brochure-button"
                  onClick={() => {
                    setType(1)
                    onHandleClick('download_brochure')
                  }}
                >
                  <i className="bi bi-download me-2"></i>
                  {project_details?.button_text}
                </button>
              </div>
            </div>
            <div className="col-md-6 achivement-side position-relative">
              <div className="row">
                <div className="col-md-6 first-img">
                  <img
                    alt="No Img"
                    src={`${Config.imageUrl}/${project_details?.project_image}`}
                    className=""
                  />
                </div>
                <div className="col-md-6 ">
                  <img
                    alt="No Img"
                    src={`${Config.imageUrl}/${project_details?.project_image2}`}
                    className="sec-img"
                  />
                </div>
              </div>
              {project_details?.video_link != null ? (
                <div className="vid-butn" onClick={() => setShow(true)}>
                  <span className="icon">
                    <i className="bi bi-play"></i>
                  </span>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>

      <div className="bd-light-gold section-padding">
        <div className="container">
          <div className="row merge-section">
            <div className="col-md-6 pe-0" data-aos="fade-right">
              <div className="detail-card-info">
                <img
                  src={`${Config.imageUrl}/${project_details?.highlight_image}`}
                  alt="Project Detail"
                />
              </div>
            </div>
            <div className="col-md-6 ps-0" data-aos="fade-left">
              <div className="box-info">
                <div className="featureprojects pb-0">
                  <h2 className=" text-black">
                    {project_details?.highlight_title}
                  </h2>
                </div>
                <div
                  className="text-justify"
                  dangerouslySetInnerHTML={{
                    __html: project_details?.highlight_info,
                  }}
                />
              </div>
            </div>
            <div className="col-md-6 pe-0" data-aos="fade-right">
              <div className="box-info">
                <div className="featureprojects pb-0">
                  <h2 className=" text-black">
                    {project_details?.amenties_title}
                  </h2>
                </div>
                <div
                  className="text-justify"
                  dangerouslySetInnerHTML={{
                    __html: project_details?.ame_info,
                  }}
                />
              </div>
            </div>
            <div className="col-md-6 ps-0" data-aos="fade-left">
              <div className="detail-card-info">
                <img
                  src={`${Config.imageUrl}/${project_details?.ame_image}`}
                  alt="Project Detail"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-padding">
        <div className="container">
          <div className="featureprojects pb-0">
            <span className="subTitle text-black ">Gallery</span>
            <h2 className="text-black">{revertSlug(params?.name)} Images</h2>
          </div>

          <div className="row  pb-0">
            {gallery?.map((obj, i) => {
              return i == 4 || i == 5 || i == 6 || i == 12 ? (
                <div className="col-xl-4 col-lg-4 col-sm-6 mb-4 position-relative">
                  <div className="tour-card two-card gallery">
                    <img
                      src={`${Config.imageUrl}/gallery/${obj?.image}`}
                      alt=""
                    />
                  </div>
                </div>
              ) : (
                <div className="col-xl-3 col-lg-4 col-sm-6 mb-4 position-relative">
                  <div className="tour-card gallery">
                    <img
                      src={`${Config.imageUrl}/gallery/${obj?.image}`}
                      alt=""
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className="section-padding pt-0">
        <div className="container">
          <div className="row">
            <div className="col-md-6 pe-0" data-aos="fade-right">
              {floorImages?.length > 1 ? (
                <>
                  <Slider {...settings} >
                    {floorImages?.map((img, i) => {
                      return (
                        <div className="detail-card-info">
                          <Zoom>
                            <img
                              src={`${Config.imageUrl}/${img}`}
                              alt="Project Detail"
                            />
                          </Zoom>
                        </div>
                      )
                    })}
                  </Slider>
                </>
              ) : (
                <>
                  {floorImages?.map((img, i) => {
                    return (
                      <div className="detail-card-info">
                        <Zoom>
                          <img
                            src={`${Config.imageUrl}/${img}`}
                            alt="Project Detail"
                          />
                        </Zoom>
                      </div>
                    )
                  })}</>
              )}
            </div>
            <div className="col-md-6 ps-0" data-aos="fade-left">
              <div className="box-info bd-light-gold h-100 d-flex flex-column justify-content-center">
                <h2 className="main-title">
                  {project_details?.project_name} Floor Plan
                </h2>
                <div className="d-flex justify-space-between">
                  {floor_label.map((obj, i) => {
                    return (
                      <div key={i} className="table-floor">
                        <h6>{obj.label}</h6>
                        <ul>
                          {parsedPrices.map((priceObj, j) => (
                            <li key={j}>
                              <i className="bi bi-arrow-right me-1"></i>
                              {priceObj[obj.label]}
                            </li>
                          ))}
                        </ul>
                      </div>
                    );
                  })}
                </div>
                <div className="mt-3">
                  <button
                    className="brochure-button"
                    onClick={() => {
                      setType(2)
                      onHandleClick('floor_plan')
                    }}
                  >
                    {project_details?.floor_button}
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-6 pe-0" data-aos="fade-right">
              <div className="box-info bd-light-gold">
                <div className="featureprojects pb-0">
                  <h2 className=" text-black">
                    {project_details?.feature_title}
                  </h2>
                </div>
                <div
                  className="text-justify"
                  dangerouslySetInnerHTML={{
                    __html: project_details?.feature_info,
                  }}
                />
              </div>
            </div>
            <div className="col-md-6 ps-0" data-aos="fade-left">
              <div className="detail-card-info">
                <img
                  src={`${Config?.imageUrl}/${project_details?.feature_image}`}
                  alt="Project Detail"
                />
              </div>
            </div>
            <div className="col-md-6 pe-0" data-aos="fade-left">
              <div className="detail-card-info">
                <img
                  src={`${Config?.imageUrl}/${project_details?.location_image}`}
                  alt="Project Detail"
                />
              </div>
            </div>
            <div className="col-md-6 ps-0" data-aos="fade-right">
              <div className="box-info bd-light-gold">
                <div className="featureprojects pb-0">
                  <h2 className=" text-black">
                    {project_details?.location_title}
                  </h2>
                </div>
                <div
                  className="text-justify"
                  dangerouslySetInnerHTML={{
                    __html: project_details?.location_info,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <MakeAppointment cta={cta} projectName={project_details?.project_name}/>

      <CTA contactSecton={contactSecton} section={project_details?.project_name} />

      {contact == false ? (
        <div className="fixed-button">
          <button
            className="brochure-button bg-light-gold border-none"
            onClick={() => {
              setType(0)
              onHandleClick('inquiry_now')

            }}
          >
            <i className="bi bi-telephone-forward-fill me-2"></i>
            {inquiry_button}
          </button>
        </div>
      ) : null}
      <Footer />

      <ModalVideo
        channel="youtube"
        youtube={{ mute: 0, autoplay: true }}
        isOpen={show}
        videoId={project_details?.video_link}
        onClose={() => setShow(false)}
      />

      <Modal
        title="Leave Your Details Here"
        open={contact}
        onCancel={() => setContact(false)}
        className="contact-modal"
        footer={null}
        width={600}
        centered
      >
        <ContactModal type={type} project={params?.name} onClose={onClose} section={project_details?.project_name} />
      </Modal>

    </div>
  );
};

export default ProjectDetail;
