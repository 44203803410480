import axios from "axios";

export const Config = {
  appUrl: "/",
  imageUrl: "https://www.uberluxuryconnect.com/admin/assets/front/img",
  pdfUrl:"https://www.uberluxuryconnect.com/admin/assets/lfm/pdfs"
  // imageUrl:"https://192.168.0.2/uberluxuryconnect/admin/assets/front/img"
};

export const axiosInstance = axios.create({
  // baseURL: "https://192.168.0.2/uberluxuryconnect/admin/api/",
  // baseURL: "https://ztpl.net/uberluxuryconnect/admin/api/",
  baseURL: "https://www.uberluxuryconnect.com/admin/api/",
  headers: {
    "Content-Type": "application/json",
    'Access-Control-Allow-Origin': '*'
  },
});


