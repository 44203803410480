import React, { useEffect, useState } from "react";
import Navbar from "../../Common/Navbar";
import Banner from "../../Common/Banner";
import ProjectCard from "../../Common/ProjectCard";
import { useParams } from "react-router-dom";
import { revertSlug } from "../../../utils/SlugGenerator";
import { axiosInstance } from "../../../config";
import Loader from "../../Common/Loader";
import Pagination from "../../Common/Pagination";

const CategoryProjects = () => {
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [projects, setProjects] = useState([]);
  const [bannerDetails , setBannerDetails] = useState({});

  useEffect(() => {
    getProjects();
  }, [params]);
  function getProjects() {
    setLoading(true);
    const type = params?.projects;
    axiosInstance
      .get(`categorys/${type}`)
      .then((res) => {
        const data = res?.data?.project_page?.project_info[0]?.projects;
        setBannerDetails(res?.data?.project_page?.project_bg)
        setProjects(data || []);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  }

  const [page, setPage] = useState(1);
  const [items_perpage, setItemsPerPage] = useState(9);
  const total_pages = Math.ceil(projects?.length / items_perpage);
  const final_data = projects?.slice(
    page * items_perpage - items_perpage,
    page * items_perpage
  );
  const changePage = (pagecount) => {
    if (pagecount > 0 && pagecount <= total_pages) {
      setPage(pagecount);
      // window.scrollTo(0, 400);
      window.scrollTo({
        top: 400,
        left: 0,
        behavior: "instant", // Optional if you want to skip the scrolling animation
      });
    }
  };

  return (
    <div>
      {loading && <Loader />}
      <Navbar />
      <Banner
        subtitle={bannerDetails?.project_title}
        title={`${revertSlug(params?.projects)} Projects`}
        backImage={bannerDetails?.project_img	}
      />
      <div className="">
        <div className=" bgblack">
          <div className="container">
            <div className="section-padding">
              <div className="featureprojects">
                <div className="projects">
                  <ProjectCard projectsData={final_data} />
                </div>
              </div>
              <Pagination
                final_data={final_data}
                blogs={projects}
                total_pages={total_pages}
                page={page}
                changePage={changePage}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryProjects;
