import React from "react";
import Slider from "react-slick";
import Navbar from "../../Common/Navbar";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import { Config } from "../../../config";

const BannerSlider = ({ banners, contact, buttonText }) => {
  const settings = {
    dots: false,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    waitForAnimate: false,
    arrows: true,
    autoplay: true,
  };

  return (
    <div className="banner-container">
      <Navbar />
      <div>
        <Slider {...settings} className="banner-slider">
          {banners?.map((obj, i) => {
            return (
              <div>
                <div className="bg-overlay"></div>
                <img
                  src={`${Config.imageUrl}/sliders/${obj?.image}`}
                  alt="Banner 1"
                  className="banner-image"
                />
                <div className="banner-content">
                  <p data-aos="fade-down">{obj?.title}</p>
                  <h1 data-aos="fade-down mb-3">{obj?.sub_title}</h1>
                  <div className="mt-3">
                    <Link
                      to={`${Config.appUrl}locations`}
                      className="banner-button"
                    >
                      {buttonText?.banner_button}
                    </Link>
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>

        <div className="reservation">
          <div className="call">
            <span>{buttonText?.banner_title}</span> <br />
            <a href={`callto:${contact}`} className="text-decoration-none">
              {contact}
            </a>
          </div>
          <div className="icon d-flex justify-content-center align-items-center">
            <i className="bi bi-telephone-outbound-fill"></i>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerSlider;
