import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { axiosInstance, Config } from "../../config";
import Slider from "react-slick";

const Footer = () => {
  const date = new Date();

  const [loading, setLoading] = useState(false);
  const [footerDetails, setFooterDetails] = useState({});
  const [contactDetails, setContactDetails] = useState([]);
  const [socialLinks, setSocialLinks] = useState([]);
  const [locations, setLocations] = useState([]);
  useEffect(() => {
    if (Object.keys(footerDetails)?.length == 0) {
      getFooter();
    }
  }, []);
  function getFooter() {
    setLoading(true);
    axiosInstance
      .get(`footer`)
      .then((res) => {
        const data = res?.data?.footer;
        setFooterDetails(data?.footer_section);
        const sortedArray = data?.location.sort((a, b) => b.projects.length - a.projects.length);
        setContactDetails(sortedArray)
        // setContactDetails(data?.contact_section);
        const locations = data?.contact_section?.Text_1?.split(",");
        const locationsNo = data?.contact_section?.Value_1?.split(",");
        const result = locations.map((name, index) => ({
          name: name,
          no: locationsNo[index],
        }));

        const footerIcons = data?.footer_section?.icon?.split(",");
        const footerLink = data?.footer_section?.link?.split(",");
        const merge = footerIcons.map((name, index) => ({
          icon: name,
          link: footerLink[index],
        }));
        setSocialLinks(merge);
        setLocations(result);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  }


  var settings = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };


  return (
    <div>
      <div className="bg-gold">
        <div className="container pt-4 pb-4">
          <Slider {...settings}>
            {contactDetails?.map((rera, i) => {
              return (
                <div>
                  <div className="rera-slider text-center">{rera?.city} - {rera?.rera_no}</div>
                </div>
              )
            })}
          </Slider>
        </div>
      </div>
      {/* <div className="footer">
        <div className="row w-100" style={{ justifyContent: "space-evenly" }}>
          <div className="address col-md-3">
            <img src={`${Config.imageUrl}/${footerDetails?.footer_logo}`}></img>
            <h4 dangerouslySetInnerHTML={{ __html: footerDetails?.footer_text }} />
            <p
              className="mt-4"
              dangerouslySetInnerHTML={{ __html: footerDetails?.newsletter_text }}
            />
          </div>
          <div className="explore col-md-3">
            <span>Explore</span>
            <div>
              <ul>
                <li>
                  <Link
                    to={`${Config.appUrl}`}
                    className="text-decoration-none text-white"
                  >
                    Home
                  </Link>
                </li>
                <li>
                  <Link
                    to={`${Config.appUrl}all-projects`}
                    className="text-decoration-none text-white"
                  >
                    Projects
                  </Link>
                </li>
                <li>
                  <Link
                    to={`${Config.appUrl}locations`}
                    className="text-decoration-none text-white"
                  >
                    Locations
                  </Link>
                </li>
                <li>
                  <Link
                    to={`${Config.appUrl}about-us`}
                    className="text-decoration-none text-white"
                  >
                    About Us
                  </Link>
                </li>
                <li>
                  <Link
                    to={`${Config.appUrl}blogs`}
                    className="text-decoration-none text-white"
                  >
                    Blogs
                  </Link>
                </li>
                <li>
                  <Link
                    to={`${Config.appUrl}contact-us`}
                    className="text-decoration-none text-white"
                  >
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          
          <div className="address col-md-3">
            <span>Contact</span>
            <div>{contactDetails?.contact_addresses}</div>
            <div className="contact">
              <div>
                <a
                  className="text-white text-decoration-none"
                  href={`callto:${contactDetails?.contact_numbers}`}
                >
                  <i className="bi bi-telephone"></i>{" "}
                  {contactDetails?.contact_numbers}
                </a>
              </div>
              <div>
                <a
                  className="text-white text-decoration-none"
                  href={`mailto:${contactDetails?.contact_mails}`}
                >
                  <i className="bi bi-envelope"></i> {contactDetails?.contact_mails}
                </a>
              </div>
              <div>
                {contactDetails?.working_days} , {contactDetails?.working_hours}
              </div>
            </div>
            <div className="social">
              {socialLinks?.map((social, i) => {
                return (
                  <div>
                    <a
                      className=" text-white"
                      target="_blank"
                      href={social?.link}
                    >
                      <i className={social?.icon}></i>
                    </a>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div style={{ backgroundColor: "#aa8453" }} className="pt-3 pb-3">
        <p
          className="mb-0 text-white text-center"
          dangerouslySetInnerHTML={{ __html: footerDetails?.copyright_text }}
        />
      </div> */}

      <div className="footer">
        <div className="col-md-12">
          {/* {contactDetails?.map((obj, i) => {
            return (
              <div className="mb-4" key={i}>
                <h4 className="project-location">{obj.city}</h4>
                <ul className="locations-section">
                  {obj?.projects?.map((proj, i) => {
                    return (
                      <li><Link to={`${Config.appUrl}project/${proj?.slug}`}>{proj?.project_name}</Link></li>
                    )
                  })}
                </ul>
              </div>
            )
          })} */}
          <div className="d-flex justify-content-between mobile-flex">
            <div>
              <div className="mb-4" >
                <div >
                  <h4 className="project-location">Quick Links</h4>
                  <ul className="locations-section">
                    <li><Link to={`${Config.appUrl}`}>Home</Link></li>
                    <li><Link to={`${Config.appUrl}about-us`}>About Us</Link></li>
                    <li><Link to={`${Config.appUrl}all-projects`}>All Projects</Link></li>
                    <li><Link to={`${Config.appUrl}locations`}>Locations</Link></li>
                    <li><Link to={`${Config.appUrl}blogs`}>Blog</Link></li>
                    <li><Link to={`${Config.appUrl}contact-us`}>Contact Us</Link></li>
                  </ul>
                </div>

              </div>
              <div className="mb-4" >
                <h4 className="project-location">Locations</h4>
                <ul className="locations-section">
                  {contactDetails?.map((rera, i) => {
                    return (
                      <li>{rera?.city}</li>
                    )
                  })}
                </ul>
              </div>
            </div>
            <div>
              <div>
                <div className="address">
                  <h4 className="mb-1">Social Media</h4>
                  <div className="social d-flex gap-2 mt-0 pt-2">
                    {socialLinks?.map((social, i) => {
                      return (
                        <div>
                          <a
                            className=" text-white "
                            target="_blank"
                            href={social?.link}
                          >
                            <i className={social?.icon}></i>
                          </a>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="mt-4">
                  <p dangerouslySetInnerHTML={{ __html: footerDetails?.disclaimer }} className="mb-0 font-Gilda" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ backgroundColor: "#aa8453" }} className="pt-3 pb-3">
        <p
          className="mb-0 text-white text-center"
          dangerouslySetInnerHTML={{ __html: footerDetails?.copyright_text }}
        />
      </div>
    </div >
  );
};
export default Footer;
