import React, { useState } from "react";
import { axiosInstance, Config } from "../../config";
import { Modal } from "antd";
import ContactModal from "./ContactModal";
import { InlineWidget, PopupModal } from "react-calendly";
import CalendlyPopup from "./CalendlyPopup";

const MakeAppointment = ({ cta, projectName }) => {
  const [contact, setContact] = useState(false);

  const onHandleClick = async () => {
    setContact(true)
    try {
      const res = await axiosInstance.get(`count/${'schedule_visit'}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const description = cta?.cta_section_button_text?.replace("$project", projectName != undefined ? projectName : " Project")



  return (
    <div>
      <div
        className="cta-section section-padding backgroun-no-repeat background-size-cover"
        style={{
          background: `linear-gradient(rgba(0,0,0,0.4),rgba(0,0,0,0.4)),url(${Config.imageUrl}/${cta?.cta_bg})`,
          backgroundBlendMode: 'darken',
          backgroundRepeat: "no-repeat"
        }}
      >
        <div className="container mt-4 mb-4">
          <div className="text-center">
            <h2>{cta?.cta_section_text}</h2>
            <p className="col-md-10 " dangerouslySetInnerHTML={{ __html: description }} />
          </div>
          <div className="mt-5 text-center">
            <button
              className="brochure-button bg-gold"
              onClick={onHandleClick}
            >
              {projectName != undefined ? (
                <>
                  {cta?.cta_section_button_url + " for " + projectName}
                </>
              ) : (
                <>
                  {cta?.cta_section_button_url}
                </>
              )}
            </button>
          </div>
        </div>
      </div>
      <Modal
        title="Schedule a Meeting"
        open={contact}
        onCancel={() => setContact(false)}
        className="contact-modal"
        footer={null}
        width={1100}
      >
        <CalendlyPopup />
      </Modal>

    </div >
  );
};

export default MakeAppointment;
