import React from 'react'
import Whatsapp from "../../assets/Images/WhatsApp.svg"

const WhatsappIcon = () => {
    const phoneNumber = "9221331700"; // Replace with your phone number
    const message = "Hi! I'm interested in exploring real estate options. Can you share the latest listings and offers from your website?"; // Optional predefined message
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

    return (
        <div className=''>
            <a href={whatsappUrl} target="_blank" rel="noopener noreferrer" className="whatsapp-icon">
                <img src={Whatsapp} alt="WhatsApp" />
            </a>
        </div>
    )
}

export default WhatsappIcon
